import React from "react";
import BannerMoc from "../assets/images/mocs/banner-moc-1-1.png";
const Banner = () => {
  return (
    <section className="banner-one" id="banner">
      <span className="banner-one__shape-1"></span>
      <span className="banner-one__shape-2"></span>
      <span className="banner-one__shape-3"></span>
      <span className="banner-one__shape-4"></span>
      <div className="container">
        <div className="banner-one__moc">
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-one__content">
              <h3 className="banner-one__title">
                An <span>Eye</span> <br /> into the health of your patients.
              </h3>
              <p className="banner-one__text">
                Blending Artificial Intelligence and Eye Tracking to screen for Mental Health.
              </p>
              {/* <a href="#none" className="banner-one__btn thm-btn ">
                <span>Get Started</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
